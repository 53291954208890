// @codekit-prepend "lib/Modernizr.js"
// @codekit-prepend "kit/kit.js"
// @codekit-prepend "pure/pure.js"

ready(function () {
  k('.sub-nav li a').forEach(function (el) {
    k(el).click(function(event) {
      var a = k(this),
          li = a.parent();
      k('.main .item').eq(k.index(k('.sub-nav li'), li[0])).removeClass('hidden')
        .siblings('div').addClass('hidden');
      k('.sub-nav li').removeClass('current');
      li.addClass('current');

      (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
    });
  });

  // hide first "prev", last "next"
  var itemLength = k('.main .item').length - 1,
      lastNext = k('.main .item').eq( itemLength ).find('.direction .next'); 
  k('.main .item').eq(0).find('.direction .prev').hide();
  if ( lastNext ) { lastNext.hide(); }

  // prev
  k('.direction .prev').click(function() {
    var parent = k(this).parent().parent()
        thisIndex = k.index(k('.main .item'), parent[0]);
    k('.main .item').addClass('hidden')
      .eq( thisIndex - 1 ).removeClass('hidden');
    k('.sub-nav li').removeClass('current')
      .eq( thisIndex - 1 ).addClass('current');
  });

  // next
  k('.direction .next').click(function() {
    var parent = k(this).parent().parent()
        thisIndex = k.index(k('.main .item'), parent[0]);
    k('.main .item').addClass('hidden')
      .eq( thisIndex + 1 ).removeClass('hidden');
    k('.sub-nav li').removeClass('current')
      .eq( thisIndex + 1 ).addClass('current');
  });

  // brand assets
  k('.brand_th span').forEach(function(el) {
    k(el).click(function(){
      var targetClass = '.communications__' + k(this).attr('class'),
          thisIndex = k.index(k('.main .item'), k(targetClass)[0]);
      k('.main .item').addClass('hidden');
      k(targetClass).removeClass('hidden');

      k('.sub-nav li').removeClass('current')
        .eq( thisIndex ).addClass('current');
      });
  });
});


